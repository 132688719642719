
	import { Component, Vue } from "vue-property-decorator";
	import { Option, Page, Select } from "view-design";
	import request from "@/request"; // 连接请求
	import { Route } from "vue-router";
	import qs from "qs";
	// import G2 from "@antv/g2";

	@Component({
  components: {},
  filters: {},
})
		export default class Login extends Vue {
  floors: { name: string; id: number }[] = [];
  countType: "0" | "1" = "1";
  showDetail: boolean = false;
  roomCount: number = 0;
  usageCount: number = 0;
  departmentCount: number = 0;
  type: string = "account";
  countdown: string = "获取验证码";
  timer: number = 0;
  // form: { tel: string; short_message: string } = {};
  // form2: { name: string; password: string } = {};

  form = {
    tel: "",
    short_message: ""
  };
  form2 = {
    name: "",
    password: ""
  };

  isTel () {
    return (
      !/0?(13|14|15|18|17)[0-9]{9}/.test(this.form.tel) || this.countdown !== "获取验证码"
    );
  }

  getVerification () {
    // if (/0?(13|14|15|18|17)[0-9]{9}/.test(this.form.tel)) {
    //   fetch("/user/short/message/?" + qs.stringify({ tel: this.form.tel }), {
    //     method: "get",
    //     headers: new Headers({
    //       "Content-Type": "application/json",
    //     }),
    //   }).then((res) => {
    //     if (res.ok) {
    //       console.log(res);
    //       console.log(res.json(), "this.res.json");
    //       res.json().then((data) => {
    //         console.log(data);
    //         if (data.Message !== "OK") {
    //           this.$Message.info(data.Message);
    //           clearInterval(this.timer);
    //           this.countdown = "获取验证码";
    //         } else {
    //           let i = 60;
    //           this.timer = setInterval(() => {
    //             i--;
    //             if (i === 0) {
    //               clearInterval(this.timer);
    //               this.countdown = "获取验证码";
    //             } else {
    //               this.countdown = i + "秒后再试";
    //             }
    //           }, 1000);
    //         }
    //       });
    //     }
    //   });
    // }
    // if (/0?(13|14|15|18|17)[0-9]{9}/.test(this.form.tel)) {
    //   request
    //     ({
    //       url: "/user/short/message/?" + qs.stringify({ tel: this.form.tel }
    //       method: "get",
    //       headers: new Headers({
    //         "Content-Type": "application/json",
    //       }),
    //     })
    //     .then((res) => {
    //       console.log(res, "打印的是res");
    //     });
    // }
  }

  submit () {
    if (this.type === "tel") {
    } else {
    //   console.log(this.form2);
      // 账号登录
      if (this.form2.name && this.form2.password) {
            const data = new FormData();
            data.append("username", this.form2.name);
            data.append("password", this.form2.password);
            request({
            url: "/pclogin/",
            method: "post",
            data
            })
          .then(({ data }) => {
            if (data.res == "succ") {
                this.$router.push("/home2");
            } else {
                this.$Message.error({
                    content: "登录失败，请确认信息后再试！",
                    duration: 15
                });
            }
          })
          .catch(() => {
                this.$Message.error({
                content: "登录失败，请确认信息后再试！",
                duration: 15
                });
          });
      }
    }
  }

  mounted () {}

  setup () {}

	// beforeRouteLeave(to: Route, from: Route, next: Function) {}
	}
